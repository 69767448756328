import { Numbers } from "web3";
import { NETWORK } from "../config/config";

export const extractErrorMessage = (rawMessage?: string) => {
  if (!rawMessage) {
    return "No error were specified";
  }

  const jsonInString = rawMessage.split("Internal JSON-RPC error.")?.[1]; // Remove text before the stringified json
  if (!jsonInString) {
    return "Internal JSON-RPC error";
  }
  const json = JSON.parse(jsonInString);

  return json.message;
};

// export const extractErrorMessage = (rawMessage?: string) => {
//   if (!rawMessage) {
//     return "No error were specified";
//   }

//   const jsonInString = rawMessage.split("Internal JSON-RPC error.")?.[1]; // Remove text before the stringified json
//   const json = JSON.parse(jsonInString);

//   let message = json.message.split("transaction: revert ")?.[1];
//   return message;
// };

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getCurrentNetworkId = async () => {
  const net_version = await window.ethereum.request({ method: "net_version" });
  return Number(net_version);
};

export const checkIfGoodNetwork = (currentNetworkId: number | null) => {
  if (!currentNetworkId) {
    return false;
  }
  return currentNetworkId === NETWORK.networkId;
};

export const bigIntToNumber = (bigInt: Numbers | undefined) => {
  if (!bigInt) {
    return -1;
  }
  const maybeNumber = window.web3.utils.toNumber(bigInt);
  if (typeof maybeNumber === "number") {
    return maybeNumber;
  }
  console.log("type number", typeof maybeNumber === "number");

  return 0;
};

import { CopyOutlined } from "@ant-design/icons";
import { Badge, message, Tooltip } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ENV_NAME, LOTTERY_INFOS, NETWORK } from "../../config/config";
import style from "./Header.module.scss";

type Props = {
  userBalance: string;
  accountAddress: string;
};

export default function Header({ userBalance, accountAddress }: Props) {
  console.log("userBalance", userBalance);

  const roundedBalance = Number(userBalance).toFixed(4);

  const formatedAddress = `${accountAddress.slice(
    0,
    6
  )}...${accountAddress.slice(-6)}`;

  return (
    <div className={style.header}>
      <div className={style.left}>
        <Tooltip
          title={
            <div className={style.tooltipContent}>
              <p>Env: {ENV_NAME}</p>
              <p>Network: {NETWORK.name}</p>
            </div>
          }
        >
          <h2>Lottery</h2>
        </Tooltip>
      </div>
      <div className={style.right}>
        <CopyToClipboard
          text={accountAddress}
          onCopy={() => message.info("Address copied !")}
        >
          <div className={style.copyToClipboard}>
            <p>{formatedAddress}</p>
            <CopyOutlined />
          </div>
        </CopyToClipboard>
        <Badge
          count={`${roundedBalance} ${LOTTERY_INFOS.currency}`}
          color="rgb(58, 58, 58)"
        />
      </div>
    </div>
  );
}

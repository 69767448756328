import { useCallback, useEffect, useState } from "react";
// @ts-expect-error
import TruffleContract from "@truffle/contract";
import { Numbers, Web3, Web3BaseProvider } from "web3";
import BigNumber from "bignumber.js";
import lotteryJson from "../contract/Lottery.json";
import { bigIntToNumber, extractErrorMessage } from "../utils/utils";
import { Modal } from "antd";
import { LOTTERY_INFOS, ENV_NAME, NETWORK } from "../config/config";
import useCurrentNetwork from "./useCurrentNetwork";
import useAccount from "./useAccount";
import { getTxGasData } from "../utils/gasUtils";
import { LotteryInstance } from "../../../types/truffle-contracts/Lottery";
import { Tickets } from "../types/tickets";

console.log(" ");
console.log("Lottery Json");
console.log(lotteryJson);
console.log(" ");

console.log(" ");
console.log("ENV_NAME", ENV_NAME);
console.log(" ");

export default function useLotteryContract() {
  const [noMetamask, setNoMetamask] = useState(false);
  const [ready, setReady] = useState(false);
  const [provider, setProvider] = useState<Web3BaseProvider | null>(null);
  const [tickets, setTickets] = useState<Tickets>([]);
  const [players, setPlayers] = useState<string[]>([]);
  const [userBalance, setUserBalance] = useState("");
  const [playerLotteryBalance, setPlayerLotteryBalance] = useState("");
  const [lotteryBalance, setLotteryBalance] = useState("");
  const [winner, setWinner] = useState("");
  const [Lottery, setContract] = useState<LotteryInstance | null>(null);
  const [lotteryName, setLotteryName] = useState("");

  // Account
  const { loadAccount, accountAddress } = useAccount();

  // Network
  const { goodNetwork, addNetwork, switchNetwork } =
    useCurrentNetwork(provider);

  // Loaders
  const [isParticipationLoading, setIsParticipationLoading] = useState(false);
  const [isChoosingWinnerLoading, setIsChoosingWinnerLoading] = useState(false);

  /**
   * Load/init methods
   */

  const loadWeb3 = useCallback(async () => {
    // Modern dapp browsers...
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      setProvider(window.web3.provider || null);
      try {
        // Request account access if needed
        // await window.ethereum.enable();

        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } catch (error) {
        // User denied account access...
      }
    }
    // if (window.ethereum) {
    //   try {
    //     window.web3 = new Web3(window.ethereum);
    //     setProvider(window.web3.provider || null);

    //     // Request access to the user's MetaMask account
    //     await window.ethereum.enable();

    //     // Provoke a provider issue on staging
    //     // await window.ethereum.request({
    //     //   method: "eth_requestAccounts",
    //     // });
    //   } catch (error: any) {
    //     // User denied account access...
    //     if (error.code === 4001) {
    //       // User rejected request
    //     }
    //   }
    // }
    // Legacy dapp browsers...
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      // Acccounts always exposed
      window.web3.eth.sendTransaction({
        /* ... */
      });
    }
    // Non-dapp browsers...
    else {
      setNoMetamask(true);
      // console.log(
      //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
      // );
    }
  }, []);

  const loadContract = useCallback(async () => {
    // Create a JavaScript version of the smart contract
    const _Lottery = TruffleContract(lotteryJson);
    _Lottery.setNetwork(NETWORK.networkId);
    _Lottery.setProvider(provider);
    // Hydrate the smart contract with values from the blockchain
    const lottery = (await _Lottery.deployed()) as LotteryInstance;
    setContract(lottery);
  }, [provider]);

  const init = useCallback(async () => {
    await loadWeb3();
    await loadAccount();
  }, [loadWeb3, loadAccount]);

  const loadUserBalance = useCallback(async () => {
    const _balanceBigInt = await window.web3.eth.getBalance(accountAddress);
    const _balanceInEth = window.web3.utils.fromWei(_balanceBigInt, "ether");
    setUserBalance(_balanceInEth);
  }, [accountAddress, setUserBalance]);

  const loadLotteryBalance = useCallback(async () => {
    const lotteryBalanceBN = await Lottery?.getLotteryBalance();

    // @ts-ignore
    const BNFixed = new BigNumber(lotteryBalanceBN); // use "BigNumber" from bignumber.js as a fix for the "toNumber()" bytes error
    const lotteryBalanceNumber = BNFixed.toNumber();

    const lotteryBalanceInEth =
      lotteryBalanceNumber === 0
        ? "0"
        : window.web3.utils.fromWei(lotteryBalanceNumber, "ether");

    setLotteryBalance(lotteryBalanceInEth);
  }, [Lottery]);

  const getWinner = useCallback(
    async (display?: string) => {
      try {
        const rawWinner = await Lottery?.winner();

        const hasWinner = !rawWinner?.includes("0x000000");
        const _winner = hasWinner ? rawWinner : "";

        if (display === "modal") {
          Modal.info({
            title: "Winner",
            content: hasWinner ? _winner : "No winner yet",
          });
        }

        return hasWinner ? _winner : "";
      } catch (e: any) {
        console.log(e.message);
        Modal.error({
          type: "error",
          title: "Erreur",
          content: extractErrorMessage(e.message),
        });
      }
    },
    [Lottery]
  );

  const loadWinner = useCallback(async () => {
    const _winner = await getWinner();
    setWinner(_winner || "");
  }, [getWinner]);

  /**
   * Tickets methods
   */

  const getTickets = useCallback(async () => {
    const ticketsCountBN = await Lottery?.balanceOf(accountAddress);
    const ticketsCount = Number(ticketsCountBN?.toString() || "0");

    const _tickets: Tickets = [];
    const baseTokenURI = await Lottery?.baseTokenURI();

    for (let i = 0; i < ticketsCount; i++) {
      const tokenIdBN = await Lottery?.tokenOfOwnerByIndex(accountAddress, i);
      const tokenId = tokenIdBN?.toString();
      if (tokenId) {
        _tickets.push({
          id: tokenId,
          imageUrl: `${baseTokenURI}/${tokenId}.svg`,
        });
      }
    }
    setTickets(_tickets);
  }, [Lottery, accountAddress]);

  /**
   * Players methods
   */

  const getPlayers = useCallback(async () => {
    const _players = await Lottery?.getPlayers();
    setPlayers(_players || []);
  }, [Lottery]);

  const getPlayersCount = useCallback(async () => {
    const playersCount = await Lottery?.playersCount();
    Modal.info({
      content: `${playersCount?.toString() || 0} players`,
    });
    await getPlayers();
  }, [Lottery, getPlayers]);

  /**
   * Lottery
   */

  const participate = useCallback(
    async (ticketCount: number) => {
      try {
        setIsParticipationLoading(true);

        // const gasPrice = await window.web3.eth.getGasPrice();
        // console.log("gasPrice", gasPrice);

        // TODO later cleaner
        const txGasData = await getTxGasData();
        console.log(" ");
        console.log("txGasData");
        console.log(txGasData);
        console.log(" ");

        const value = ticketCount * Number(LOTTERY_INFOS.ticketPrice);

        console.log("value", value);
        console.log("value to string", value.toString());
        console.log(
          "value final",
          window.web3.utils.toWei(value.toString(), "ether")
        );

        const estimatedGas = await Lottery?.participate.estimateGas(
          ticketCount,
          {
            from: accountAddress,
            value: window.web3.utils.toWei(value.toString(), "ether"),
          }
        );
        console.log("estimatedGas", estimatedGas);

        await Lottery?.participate(ticketCount, {
          from: accountAddress,
          value: window.web3.utils.toWei(value.toString(), "ether"),
          ...txGasData,
        });

        Modal.success({
          type: "error",
          title: "Registered 🥂",
          content: "Welcome on board",
        });

        getPlayers();
        loadUserBalance();
        loadLotteryBalance();
        getTickets();
      } catch (e: any) {
        console.log(e.message);

        if (e.message?.includes("User denied transaction")) {
          console.error("User denied");
          return;
        }

        Modal.error({
          type: "error",
          title: "Erreur",
          content: extractErrorMessage(e.message),
        });
      } finally {
        setIsParticipationLoading(false);
      }
    },
    [
      Lottery,
      accountAddress,
      getPlayers,
      loadUserBalance,
      loadLotteryBalance,
      getTickets,
    ]
  );

  const chooseWinner = useCallback(async () => {
    try {
      setIsChoosingWinnerLoading(true);

      // TODO later cleaner
      const txGasData = await getTxGasData();
      console.log(" ");
      console.log("txGasData");
      console.log(txGasData);
      console.log(" ");

      const estimatedGas = await Lottery?.chooseWinner.estimateGas({
        from: accountAddress,
      });
      console.log("estimatedGas", estimatedGas);

      await Lottery?.chooseWinner({
        from: accountAddress,
        // gas: estimatedGas,
        ...txGasData,
      });
      loadWinner();
      getPlayers();
      loadUserBalance();
      loadLotteryBalance();
      getTickets();
    } catch (e: any) {
      console.log(e.message);

      if (e.message?.includes("User denied transaction")) {
        console.error("User denied");
        return;
      }

      Modal.error({
        type: "error",
        title: "Erreur",
        content: extractErrorMessage(e.message),
      });
    } finally {
      setIsChoosingWinnerLoading(false);
    }
  }, [
    Lottery,
    accountAddress,
    loadWinner,
    loadLotteryBalance,
    getPlayers,
    loadUserBalance,
    getTickets,
  ]);

  const getLotteryName = useCallback(async () => {
    try {
      const lotteryName = await Lottery?.lotteryName();
      setLotteryName(lotteryName || "");
    } catch (e) {
      console.log("getLotteryName error", e);
    }
  }, [Lottery]);

  /**
   * Effects
   */

  // Effect to trigger "ready"
  useEffect(() => {
    if (provider && Lottery && !ready) {
      setReady(true);
    }
  }, [provider, Lottery, loadContract, ready]);

  // Effect to trigger "loadContract()"
  useEffect(() => {
    if (provider && !Lottery) {
      loadContract();
    }
  }, [provider, Lottery, loadContract]);

  // Effect to load balances
  useEffect(() => {
    const exec = async () => {
      if (accountAddress && goodNetwork) {
        loadUserBalance();
        loadLotteryBalance();
        loadWinner();
        getTickets();
      }
    };
    exec();
  }, [
    accountAddress,
    loadUserBalance,
    loadLotteryBalance,
    loadWinner,
    goodNetwork,
    getTickets,
  ]);

  // onMoun: get lottery name
  useEffect(() => {
    if (Lottery && !lotteryName) {
      getLotteryName();
    }
  }, [Lottery, getLotteryName, lotteryName]);

  return {
    noMetamask,
    isConnected: !!accountAddress,
    isOnGoodNetwork: goodNetwork,
    addNetwork,
    switchNetwork,
    ready,
    init,
    lotteryName,
    userBalance,
    playerLotteryBalance,
    accountAddress,
    tickets,
    // getTickets,
    players,
    getPlayers,
    getPlayersCount,
    getPlayerBalance: getTickets,
    participate,
    // Loterry prize
    lotteryBalance,
    getLotteryBalance: loadLotteryBalance,
    chooseWinner,
    winner,
    getWinner,
    // Loaders
    isParticipationLoading,
    isChoosingWinnerLoading,
  };
}

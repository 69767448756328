import React, { useEffect } from "react";
import "antd/dist/reset.css";
import "./App.css";
import LotteryCard from "./components/LotteryCard/LotteryCard";
import Players from "./components/Players/Players";
import useLotteryContract from "./hooks/useLotteryContract";
import { Alert, Button } from "antd";
import {
  ApiOutlined,
  DownloadOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Header from "./components/Header/Header";
import Results from "./components/Results/Results";
import { NETWORK } from "./config/config";
import Tickets from "./components/Tickets/Tickets";
import LotteryActions from "./components/LotteryActions/LotteryActions";

function App() {
  const {
    noMetamask,
    isConnected,
    isOnGoodNetwork,
    ready,
    init,
    addNetwork,
    switchNetwork,
    lotteryName,
    userBalance,
    playerLotteryBalance,
    accountAddress,
    players,
    getPlayers,
    getPlayersCount,
    getPlayerBalance,
    tickets,
    participate,
    lotteryBalance,
    getLotteryBalance,
    winner,
    chooseWinner,
    getWinner,
    // loaders
    isParticipationLoading,
    isChoosingWinnerLoading,
  } = useLotteryContract();

  // On mount
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (noMetamask) {
    return (
      <>
        <div id="obfuscator" />
        <div className="alertContainer">
          <img src="/metamask.png" alt="" className="metamaskLogo" />
          <Alert
            message="Non-Ethereum browser detected. You should consider trying MetaMask !"
            type="info"
          />
          <br />
          <Button
            type="primary"
            href="https://metamask.io/"
            target="_blank"
            icon={<DownloadOutlined />}
          >
            Install MetaMask
          </Button>
        </div>
      </>
    );
  }

  if (!isConnected) {
    return (
      <>
        <div id="obfuscator" />
        <div className="alertContainer">
          <div className="alertContainer">
            {/* <img src="/logo192.png" alt="" className="logoWojak" /> */}
            <Alert message={`Anon, please connect to MetaMask`} type="info" />
          </div>
        </div>
      </>
    );
  }

  if (!isOnGoodNetwork) {
    return (
      <>
        <div id="obfuscator" />
        <div className="alertContainer">
          <div className="alertContainer">
            {/* <img src="/logo192.png" alt="" className="logoWojak" /> */}
            <Alert
              message={`Anon, please connect to the "${NETWORK.displayName}" network`}
              type="warning"
            />
            <br />
            <Button
              type="link"
              href={NETWORK.helpLinkSetup}
              target="_blank"
              icon={<QuestionCircleOutlined />}
            >
              Add the "{NETWORK.displayName}" network on MetaMask
            </Button>
            <br />
            <br />
            <Button icon={<ApiOutlined />} onClick={addNetwork}>
              Add network
            </Button>
            <br />
            <br />
            <Button icon={<ApiOutlined />} onClick={switchNetwork}>
              Switch
            </Button>
          </div>
        </div>
      </>
    );
  }

  if (!ready) {
    return (
      <div className="alertContainer">
        <LoadingOutlined />
      </div>
    );
  }

  return (
    <>
      <div id="obfuscator" />
      <div className="App">
        <Header userBalance={userBalance} accountAddress={accountAddress} />
        <div className="content">
          <div className="sectionSpacer" />
          {!!winner && (
            <Results winner={winner} accountAddress={accountAddress} />
          )}
          <LotteryCard
            lotteryBalance={lotteryBalance}
            lotteryName={lotteryName}
            playerLotteryBalance={playerLotteryBalance}
          />
          <div className="sectionSpacer" />
          <LotteryActions
            participate={participate}
            chooseWinner={chooseWinner}
            isParticipationLoading={isParticipationLoading}
            isChoosingWinnerLoading={isChoosingWinnerLoading}
          />
          <div className="sectionSpacer" />
          <Tickets tickets={tickets} />
          <div className="sectionSpacer" />
          <h1 className="title">
            Registered players{" "}
            <span style={{ fontSize: "16px" }}>({players.length})</span>
          </h1>
          <Players getPlayers={getPlayers} players={players} />
          <div className="sectionSpacer" />
          <h1 className="title">Contract methods (debug)</h1>
          <div className="btnRow">
            <Button type="dashed" onClick={() => getWinner("modal")}>
              Display Winner
            </Button>
            <Button type="dashed" onClick={getPlayersCount}>
              Display Players Count
            </Button>
            <Button type="dashed" onClick={getLotteryBalance}>
              Refresh Lottery Balance
            </Button>
            <Button type="dashed" onClick={getPlayers}>
              Refresh Players
            </Button>
            <Button type="dashed" onClick={getPlayerBalance}>
              Refresh my balance
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;

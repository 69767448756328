import { useCallback, useEffect, useState } from "react";
import { NETWORK } from "../config/config";
import { checkIfGoodNetwork, getCurrentNetworkId } from "../utils/utils";
import { log } from "console";
import { Web3BaseProvider } from "web3";

export default function useCurrentNetwork(provider: null | Web3BaseProvider) {
  const [currentNetworkId, setCurentNetworkId] = useState<number | null>(null);

  const onNetworkChanged = useCallback((_currentNetworkId: number) => {
    console.log("Network changed", _currentNetworkId);
    setCurentNetworkId(_currentNetworkId);
  }, []);

  useEffect(() => {
    const checkAsync = async () => {
      const _currentNetworkId = await getCurrentNetworkId();
      setCurentNetworkId(_currentNetworkId);
    };
    checkAsync();
  });

  // "Is on good network" on change effect
  useEffect(() => {
    if (!window.ethereum) {
      return;
    }
    window.ethereum.on("chainChanged", onNetworkChanged);
  }, [onNetworkChanged]);

  const goodNetwork = checkIfGoodNetwork(currentNetworkId);

  const switchNetwork = useCallback(async () => {
    // const chainId = await window.web3.eth.getChainId();
    // console.log("DEBUG chainId", chainId);

    const chainIdHex = window.web3.utils.numberToHex(NETWORK.chainId);
    console.log("chainIdHex", chainIdHex);

    try {
      await provider?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdHex }],
      });
    } catch (switchError) {
      console.log(
        "This error code indicates that the chain has not been added to MetaMask."
      );
    }
  }, [provider]);

  const addNetwork = useCallback(async () => {
    // const chainId = await window.web3.eth.getChainId();
    // console.log("DEBUG chainId", chainId);

    const chainIdHex = window.web3.utils.numberToHex(NETWORK.chainId);
    console.log({
      params: [
        {
          chainId: chainIdHex,
          chainName: NETWORK.chainName,
          nativeCurrency: NETWORK.nativeCurrency,
          rpcUrls: NETWORK.rpcUrls,
          blockExplorerUrls: NETWORK.blockExplorerUrls,
        },
      ],
    });

    try {
      // DOC: https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
      await provider?.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: chainIdHex,
            chainName: NETWORK.chainName,
            nativeCurrency: NETWORK.nativeCurrency,
            rpcUrls: NETWORK.rpcUrls,
            blockExplorerUrls: NETWORK.blockExplorerUrls,
          },
        ],
      });
    } catch (switchError) {
      console.log(
        "This error code indicates that the chain has not been added to MetaMask."
      );
    }
  }, [provider]);

  return {
    goodNetwork,
    addNetwork,
    switchNetwork,
  };
}
